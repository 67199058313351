.nav-bar {
  background: #181818;
  width: 100%;
  z-index: 3;
  position: fixed;
  display: flex;
  flex: 1;
  justify-content: space-evenly;
  top: 0;
  
  .logo {
    display: flex;
    align-items: center;
    padding: 5px 0;
    @media screen and (max-width: 768px) {
      display: none;
    }
    img {
      margin: 8px auto;
      width: 65px;

      &.sub-logo {
        width: 50px;
      }
    }
  }

  nav {
    text-align: center;
    display: flex;
    align-items: center;
    
    a {
      font-size: 25px;
      color: #4d4d4e;
      display: block;
      padding: 0px 30px;
      position: relative;
      @media screen and (max-width: 768px) {
        font-size: 20px;
        padding: 0px 20px;
      }
      i {
        transition: all 0.3s ease-out;
      }

      &:hover {
        color: #0098ea;

        svg {
          opacity: 0.5;
        }

        &:after {
          opacity: 1;
        }
      }
      
      &:after {
        content: '';
        text-align: center;
        font-size: 11px;
        position: absolute;
        bottom: 10px;
        right: 23px;
        opacity: 0;
        -webkit-transition: all 0.3s ease-out;
        transition: all 0.3s ease-out;
      }
    }

    a.active {
      svg {
        color: #0098ea;
      }
    }
  }

  ul {
    text-align: center;
    display: flex;
    align-items: center;
    list-style: none;

    li {
      a {
        font-size: 25px;
        color: #4d4d4e;
        margin-right: 20px;
        @media screen and (max-width: 768px) {
          font-size: 20px;
          margin-right: 15px;
        }
        &:hover {
          color: #0098ea;
        }
      }
    }
  }
}

.random {
  color: #b9b9b9;
}

.text-zone {
  h1 {
    color: #fff;
    font-size: 2.5rem;
    margin: 0;
    font-family: 'Coolvetica';
    font-weight: 400;
    @media screen and (max-width: 768px) {
      font-size: 1rem;
      white-space: nowrap;
    }
  }
}

.text-animate-hover {
  min-width: 10px;
  display: inline-block;
  animation-fill-mode: both;
  animation: rubberBand 1s;

  &:hover {
    animation: none;
    color: #0098ea;
  }
}

.flat-button {
  color: #0098ea;
  font-size: 1.5rem;
  font-weight: 600;
  letter-spacing: 3px;
  font-family: sans-serif;
  text-decoration: none;
  padding: 8px 16px;
  border: 1px solid #0098ea;
  float: left;
  animation: fadeInAnimation 1s 1.8s backwards;
  white-space: nowrap;
  border-radius: 0.5rem;

  &:hover {
    cursor: pointer;
    background: #0098ea;
    color: rgb(0, 0, 0);
  }
  @media screen and (max-width: 768px) {
    font-size: 1rem;
  }
}

.side-container {
  display: flex;
  align-items: center;
  gap: 2rem;
  padding: 1rem;
  border-bottom-width: 1px;
  border-color: #ffffff;
  border-left-width: 1px;
  border-right-width: 1px;
  border-top-width: 1px;
  border-style: solid;
  background-color: #181818;
  border-radius: 15px;
  box-shadow: 8px 5px #fff;
  overflow: hidden;
  position: relative;

  @media screen and (max-width: 768px) {
    padding: 0;
    padding-left: 1rem;
  }
}