.home-page {
  .text-zone {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 55%;
    max-height: 90%;
    @media screen and (max-width: 768px) {
      top: 70%;
      width: 95%;
    }
    h1 {
      color: #fff;
      font-size: 56px;
      line-height: 63px;
      margin: 0;
      font-family: 'Coolvetica';
      font-weight: 400;
      @media screen and (max-width: 768px) {
        font-size: 31px;
        white-space: nowrap;
      }
      &:before {
        content: '<BUY>';
        font-family: 'La Belle Aurore', cursive;
        color: #0098ea;
        margin-left: 25px;
        opacity: 0.7;
      }

      &:after {
        content: '<BUY/>';
        font-family: 'La Belle Aurore', cursive;
        color: #0098ea;
        margin-left: 15px;
        opacity: 0.7;
      }
    }
    h2 {
      color: #fff;
      font-size: 36px;
      line-height: 43px;
      margin: 0;
      font-family: 'Coolvetica';
      font-weight: 400;
      @media screen and (max-width: 768px) {
        font-size: 27px;
      }
    }
  }

  h2 {
    color: #8d8d8d;
    margin-top: 20px;
    font-weight: 400;
    font-size: 11px;
    font-family: sans-serif;
    letter-spacing: 3px;
    animation: fadeIn 1s 1.8s backwards;
  }

  .flat-button {
    display: flex;
    gap: 1rem;
    align-items: center;
    color: #0098ea;
    font-size: 19px;
    font-weight: 600;
    letter-spacing: 3px;
    width: 30%;
    font-family: sans-serif;
    text-decoration: none;
    padding: 10px 18px;
    border: 1px solid #0098ea;
    float: left;
    animation: fadeInAnimation 1s 1.8s backwards;
    white-space: nowrap;
    border-radius: 0.5rem;

    &:hover {
      background: #0098ea;
      color: rgb(0, 0, 0);
    }
    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }
}
