.logo-container {
  z-index: 0;
  width: 400px;
  height: 450px;
  opacity: 1;
  position: absolute;
  top: 0;
  left: 10%;
  bottom: 0;
  margin: auto;
  animation: zoomInDown 2.5s ease-in-out 1s;
  user-select: none;
    @media screen and (max-width: 768px) {
      width: 130px;
      height: 650px;
      left: 35%;
    }

  .solid-logo {
    position: absolute;
    top: auto;
    right: auto;
    bottom: auto;
    left: 0;
    margin: auto;
    width: 100%;
    opacity: 1;
    transform: rotateZ(30deg) !important;
    z-index: 1;
  }
  .flat-button {
    display: flex;
    gap: 1rem;
    align-items: center;
    color: #0098ea;
    font-size: 19px;
    font-weight: 600;
    letter-spacing: 3px;
    width: 30%;
    font-family: sans-serif;
    text-decoration: none;
    padding: 10px 18px;
    border: 1px solid #0098ea;
    float: left;
    animation: fadeInAnimation 1s 1.8s backwards;
    white-space: nowrap;
    border-radius: 0.5rem;

    &:hover {
      background: #0098ea;
      color: rgb(0, 0, 0);
    }
    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }
}

.svg-container {
  stroke: #ffd700;
  stroke-width: 10;
}
